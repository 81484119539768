import React, { Fragment, useEffect, useState, forwardRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Snackbar,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshingIndicator from "./RefreshingIndicator";
import SearchIcon from "@mui/icons-material/Search";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import MemberDetail from "./MemberDetail";
import UserService from "../services/UserService";
import ManageCard from "./ManageCard";
import MemberTransactions from "./MemberTransactions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function CompanyMembers(props) {
  const {
    isLoading,
    isFetching,
    isError,
    data,
    setPageFunction,
    setFilterStringFunction,
    dataCount,
    isLoadingDataCount,
    title,
    pageSize,
    onPageSizeChange,
    setLocationIdsFunction,
    dataLocations,
    isLoadingLocations,
  } = props;

  const [locationIds, setLocationIds] = React.useState({});
  const [elements, setElements] = React.useState([]);

  const theme = useTheme();

  const handleChangeElements = (event) => {
    const {
      target: { value },
    } = event;
    setLocationIdsFunction(value);
    setLocationIds(value);
    //refetchFunction();
    setElements(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onFilterChange = (filter, params) => {
    const filterString = filter.quickFilterValues.join(" ");
    if (filterString === undefined) {
      setFilterStringFunction("");
    } else {
      setFilterStringFunction(filterString);
    }
    return filter;
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const [openNewDlg, setOpenNewDlg] = useState(false);
  const [openDetailDlg, setOpenDetailDlg] = useState(false);

  const [memberInfo, setMemberInfo] = useState({});

  const onViewButtonClick = (e, params) => {
    setMemberInfo(params);
    setOpenNewDlg(true);
  };

  const onDetailButtonClick = (e, params) => {
    setMemberInfo(params);
    setOpenDetailDlg(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataColumns = [
    { field: "id", headerName: "ID", width: 90, hidden: true },
    {
      field: "memberId",
      headerName: "Member Id",
      width: 130,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "1rem", mb: 0 }} gutterBottom>
            {params.row.memberId}
          </Typography>
        );
      },
    },
    {
      field: "accountNumber",
      headerName: "Account #",
      width: 110,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "1rem", mb: 0 }} gutterBottom>
            {params.row.accountNumber}
          </Typography>
        );
      },
    },
    {
      field: "routingNumber",
      headerName: "Routing",
      width: 75,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "0.7rem", mb: 0 }} gutterBottom>
            {params.row.routingNumber}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "0.9rem", mb: 0 }} gutterBottom>
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 220,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "0.9rem", mb: 0 }} gutterBottom>
            {params.row.email}
          </Typography>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Created At",
      width: 130,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.createdAt}
          </Typography>
        );
      },
    },

    {
      field: "cardType",
      headerName: "Card",
      width: 90,
      renderCell: (params) => {
        return (
          <Typography
            sx={{ fontWeight: "bold", fontSize: "0.7rem", mb: 0 }}
            gutterBottom
          >
            {params.row.cardType}
          </Typography>
        );
      },
    },
    {
      field: "cardRequestStatus",
      headerName: "Enrollment",
      width: 120,
      renderCell: (params) => {
        return (
          <div
            style={{
              minWidth: "4rem",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
              {params.row.cardRequestStatus}
            </Typography>
            <Typography
              sx={{ fontSize: 8, mb: 0, fontWeight: "bold", color: "gray" }}
              gutterBottom
            >
              {params.row.customerStatus}
            </Typography>
          </div>
        );
      },
    },
    ...(UserService.hasRole(["pc-lock-unlock-cards", "pc-view-trx-cards"])
      ? [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (params) => {
              return (
                <>
                  <Tooltip title="Card Actions">
                    <Button
                      sx={{
                        display: UserService.hasRole(["pc-lock-unlock-cards"])
                          ? "block"
                          : "none",
                      }}
                      color="primary"
                      onClick={(e) => onViewButtonClick(e, params.row)}
                    >
                      <CreditCardIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="View Transactions">
                    <Button
                      sx={{
                        display:
                          UserService.hasRole(["pc-view-trx-cards"]) &&
                          params.row.cardRequestStatus === "COMPLETED"
                            ? "block"
                            : "none",
                      }}
                      color="primary"
                      onClick={(e) => onDetailButtonClick(e, params.row)}
                    >
                      <ReadMoreIcon />
                    </Button>
                  </Tooltip>
                </>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <Fragment>
      {isLoading || isLoadingDataCount || isLoadingLocations ? (
        <CircularProgress color="inherit" />
      ) : !isError ? (
        <div style={{ height: 450, width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <h1>{title}</h1>
            </div>
            {isFetching ? <RefreshingIndicator /> : ""}
          </div>
          <div
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                width: 500,
                display: UserService.hasRole(["pc-lock-unlock-cards"])
                  ? "flex"
                  : "none",
                justifyContent: "flex-end",
              }}
            >
              <InputLabel id="demo-multiple-chip-label">Locations</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={elements}
                onChange={handleChangeElements}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="LocationsChip"
                  />
                }
                renderValue={(selected, other) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((key) => {
                      return (
                        <Chip
                          key={key}
                          label={
                            dataLocations?.data.find((item) => item.id === key)
                              .description
                          }
                        />
                      );
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {dataLocations?.data.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={getStyles(option.description, elements, theme)}
                  >
                    {option.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <DataGrid
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={onPageSizeChange}
            columnVisibilityModel={{ id: false }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 800 },
              },
            }}
            rows={data?.data}
            columns={dataColumns}
            rowCount={dataCount?.data}
            onPageChange={(newPage) => {
              setPageFunction(newPage);
            }}
            paginationMode="server"
            filterMode="server"
            onFilterModelChange={onFilterChange}
            keepNonExistentRowsSelected
            getRowId={(row) => row.memberId + row.companyId}
            sx={{
              height: "calc(55vh)",
              "& .MuiButton-textSizeSmall ": { display: "none" },
              "& .MuiTypography-root": { fontFamily: "sans-serif" },
            }}
          />
          <Snackbar
            open={feedback.open}
            autoHideDuration={6000}
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={
                feedback.onClose ? feedback.onClose : handleFeedbackClose
              }
              severity={feedback.type}
              sx={{ width: "100%" }}
            >
              {feedback.message}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <h4>An error ocurred, please try again later...</h4>
      )}
      {openNewDlg && (
        <ManageCard
          open={openNewDlg}
          setOpen={setOpenNewDlg}
          dialogTitle="PayAnyDay Info"
          memberInfo={memberInfo}
        />
      )}
      {openDetailDlg && (
        <MemberTransactions
          open={openDetailDlg}
          setOpen={setOpenDetailDlg}
          dialogTitle="Member Info"
          memberInfo={memberInfo}
        />
      )}
    </Fragment>
  );
}
